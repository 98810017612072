module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDF545G","defaultDataLayer":{"type":"object","value":{}},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"lEIpoQHx3G5Jqsy3GmjcPS357D4AlwlA","devKey":"kDdX1dpmsAWuUn8zb1QDJR8YGbWJjKoj","trackPage":false,"trackPageOnlyIfReady":true,"customSnippet":"!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error(\"Segment snippet included twice.\");else{analytics.invoked=!0;analytics.methods=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\",\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"once\",\"off\",\"on\",\"addSourceMiddleware\",\"addIntegrationMiddleware\",\"setAnonymousId\",\"addDestinationMiddleware\"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement(\"script\");t.type=\"text/javascript\";t.async=!0;t.src=\"https://cdn.segment.com/analytics.js/v1/\" + key + \"/analytics.min.js\";var n=document.getElementsByTagName(\"script\")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=\"${writeKey}\";;analytics.SNIPPET_VERSION=\"4.15.3\";analytics.load(\"${writeKey}\");analytics.ready(function() {window.dataLayer.push({\"event\": \"segment_analytics_loaded\"});});analytics.page();}}();"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pantheon Documentation","short_name":"Docs","start_url":"/","background_color":"#FFFFFF","theme_color":"#EFD01B","display":"standalone","icon":"src/layout/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"25bfc9c738dbf4389dbedeb4453736a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-grid-tables"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"markdownCaptions":true,"showCaptions":false,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-code-titles","options":{"className":"gatsby-remark-code-title"}},"gatsby-remark-static-images",{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":true,"ordered":false,"fromHeading":2,"toHeading":3,"className":"table-of-contents"}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"0.75em\" viewBox=\"0 0 576 512\"><path d=\"M0 256C0 167.6 71.6 96 160 96h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H160c-53 0-96 43-96 96s43 96 96 96h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H160C71.6 416 0 344.4 0 256zm576 0c0 88.4-71.6 160-160 160H352c-17.7 0-32-14.3-32-32s14.3-32 32-32h64c53 0 96-43 96-96s-43-96-96-96H352c-17.7 0-32-14.3-32-32s14.3-32 32-32h64c88.4 0 160 71.6 160 160zM192 224H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32z\"/></svg>","className":"docs-header-anchor","maintainCase":false,"removeAccents":true,"isIconAfterHeader":true}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"noInlineHighlight":true,"aliases":{},"prompt":{"user":"user","host":"localhost"}}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener external"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/workspace","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
